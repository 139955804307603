<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;
export default {
  extends: Line,
  mixins: [reactiveProp],
  data() {
    return {
      options: {
        //Chart.js options
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: true,
              },
            },
          ],
        },
        tooltips: {
          backgroundColor: '#d84c52',
          displayColors: false,
          bodyFontSize: 18,
          xPadding: 16,
          yPadding: 16,
          callbacks: {
                label: function(tooltipItem, data) {
                    var label;
                    label = Math.round(tooltipItem.yLabel * 100) / 100;
                    return '฿ ' + label.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                },
                title() {
                  return ''
                },                
                labelTextColor: function(tooltipItem, chart) {
                    return '#fff';
                }
            }
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-card class="mt-4" v-if="loaded">
            <v-card-title>
              ข้อมูล ณ เวลา : {{ converters.dateObjectToStandardAooFormat(this.datetime) }}
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-card style="background-color: #fff6f0;">
                      <v-card-text>ยอดขายทั้งหมด</v-card-text>
                      <v-card-title class="justify-end">
                        ฿ {{ total_sale | formatNumber }}
                      </v-card-title>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-card style="background-color: #fff6f0;">
                      <v-card-text>รายการขายทั้งหมด</v-card-text>
                      <v-card-title class="justify-end">
                        {{ today_orders_count | formatNumber(0) }}
                      </v-card-title>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-card style="background-color: #fff6f0;">
                      <v-card-text>รายการขายที่ชำระ</v-card-text>
                      <v-card-title class="justify-end">
                        {{ today_paid_orders_count | formatNumber(0) }}
                      </v-card-title>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
              <saleschart :chartData="chartData" class="w-100" ref="chart"></saleschart>
            </v-card-text>            
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title>
              สินค้าออนไลน์ขายดี
            </v-card-title>

            <v-card-text>
              <v-simple-table>
                <tbody>
                    <tr v-for="(product, index) in online_best_seller" :key="'online_product_' + index">
                        <td style="width:20%">
                            <img v-if="product.image" :src="product.image" alt="" style="max-width:100px;max-height:100px">
                            <nopicture v-else></nopicture>
                        </td>
                        <td>
                            <div style="display:flex;flex-direction:column">
                                <label for="" class="kanit t-m t-w-500">{{product.name}}</label>
                                <label for="" class="kanit t-s t-w-300 muted">{{product.sku}}</label>
                            </div>
                        </td>
                        <td class="kanit">
                            {{ product.quantity }}
                        </td>
                    </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title>
              สินค้า POS ขายดี
            </v-card-title>

            <v-card-text>
              <v-simple-table>
                <tbody>
                    <tr v-for="(product, index) in pos_best_seller" :key="'pos_product_' + index">
                        <td style="width:20%">
                            <img v-if="product.image" :src="product.image" alt="" style="max-width:100px;max-height:100px">
                            <nopicture v-else></nopicture>
                        </td>
                        <td>
                            <div style="display:flex;flex-direction:column">
                                <label for="" class="kanit t-m t-w-500">{{product.name}}</label>
                                <label for="" class="kanit t-s t-w-300 muted">{{product.sku}}</label>
                            </div>
                        </td>
                        <td class="kanit">
                            {{ product.quantity }}
                        </td>
                    </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>    
  </div>
</template>
<script>
import saleschart from '@/components/charts/saleschart.vue'
import { asyncGet } from '@/helpers/asyncAxios'
import { dateObjectToStandardAooFormat } from '@/helpers/dateTimeConverter'
export default {
  data() {
    return {
      datetime: null,
      loaded: false,
      today_orders_count: 0,
      today_paid_orders_count: 0,
      total_sale: 0,
      chartData: {
        labels: [],
        datasets: [],
        hoverBackgroundColor: '#d84c52',
      },
      online_best_seller: [],
      pos_best_seller: [],
      converters: {
        dateObjectToStandardAooFormat,
      },
    }
  },
  async mounted() {
    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    await this.getData()
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
    this.loaded = true
  },
  methods: {
    async getData() {
      try {
        const {
          online_best_seller,
          pos_best_seller,
          today_orders_count,
          today_paid_orders_count,
          total_sale,
          data,
          datetime,
        } = await asyncGet('/dashboard')

        this.today_orders_count = today_orders_count
        this.today_paid_orders_count = today_paid_orders_count
        this.pos_best_seller = pos_best_seller
        this.online_best_seller = online_best_seller
        this.total_sale = total_sale

        this.datetime = new Date(datetime)

        this.updateDataset(data)

        //setTimeout(this.getData, 10000)
      } catch (error) {
        console.log(error)
      }
    },
    updateDataset(input) {
      let data = []
      let label = []

      for (const [key, value] of Object.entries(input)) {
        label.push(key)
        data.push(value)
      }

      this.chartData = {
        labels: label,
        datasets: [
          {
            label: 'ยอดขาย',
            data: data,
            backgroundColor: 'rgba(216, 76, 82, 0.2)',
            borderColor: '#d84c52',
          },
        ],
        hoverBackgroundColor: '#d84c52',
      }
    },
  },
  components: {
    saleschart,
  },
}
</script>